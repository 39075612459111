export default {
  heading: 'Playfair Display, serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Sofia, cursive',
  googleFonts: ['Open Sans:100,200,300,400,500,600,700,800,900', 'Playfair Display:400,500,600,700,800,900', 'Sofia']
  // customFamilies: ['Stannum'],
  // customUrls: [
  //   'https://gonation.biz/fonts/stannum-regular/stannum-regular.css',
  // ],
}
