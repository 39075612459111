export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'primary',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      padding: ['0.5rem', '0.25rem'],
      zIndex: '1001'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'secondary',
        padding: ['0.75rem', '0.5rem'],
        a: {
          fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem', '0.9rem'],
          border: 'none',
          fontWeight: '600',
          padding: '0rem'
        }
      },
      '.navItemDropdownMenu': {
        backgroundColor: 'primary',
        width: 'fit-content'
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem 0.75rem 0.75rem 1.5rem', '1rem 1rem 1rem 2rem'],
      width: '100%',
      flexGrow: '1',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        color: 'inherit'
      }
    },
    '.hamburger > div': {
      backgroundColor: 'secondary'
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        maxHeight: ['80px', '150px']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'black',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItemDropdown': {
        width: '100%'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        backgroundColor: 'black',
        width: 'fit-content',
        '.navItem': {
          a: {
            fontSize: '1.1rem'
          }
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'secondary',
      padding: ['1rem', '', '2rem']
    },

    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.25rem'],
        width: 'fit-content'
      },
      padding: '0.25rem',
      margin: '0rem',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        marginRight: '1rem',
        path: {
          fill: 'light',
          width: '25px',
          height: '25px'
        }
      },
      a: {
        justifyContent: 'flex-start',
        fontSize: ['0.8rem', '1rem'],
        padding: ['0.5rem 0rem', '', '1rem 0rem'],
        margin: '0rem'
      }
    },
    '.phoneContainer': {
      paddingBottom: '0.5rem'
    },

    '.containerScrolled': {
      backgroundColor: 'primary',
      borderBottom: '2px solid',
      borderColor: 'primary',
      padding: ['0.25rem', '0.25rem', '0.25rem', '0.25rem'],
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: 'secondary'
      }
    },
    '.logo': {
      img: {
        opacity: '1',
        maxHeight: ['80px', '100px']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['60px', '85px']
      }
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: '#e6ddc0',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ['', '', '', 'stretch'],
    '::after': {
      content: `"© ${new Date().getFullYear()} Villa Lulu"`,
      width: '100%',
      backgroundColor: '#202020',
      color: 'white',
      padding: '2rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '200px'
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      color: 'black',
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem']
    },
    '.column': {
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', '350px']
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },
    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'primary',
      margin: ['', '', '', '0 0 0 auto'],
      marginBottom: '0rem',
      padding: ['', '', '4rem 3rem', '4rem 4rem'],
      width: ['100%', '', '', '40%'],
      '.sectionHeading': {
        color: 'white',
        fontSize: '2rem',
        textAlign: 'left',
        width: '100%',
        padding: ['1.25rem', '', '', '0rem'],
        fontWeight: '400'
      },

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'white',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.poweredBy  .gonationLogo': {
      filter: 'unset'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'black'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      color: 'text'
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    '::before': {
      content: "''",
      width: '96%',
      height: '125%',
      border: 'solid 1px',
      borderColor: 'secondary',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    whiteSpace: 'no-wrap',
    width: '100%',
    padding: '1rem 2rem',
    position: 'relative',
    border: 'solid 1px',
    borderColor: 'secondary',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2.5rem',
    order: '1',
    color: 'text',
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    order: '2',
    fontFamily: 'display',
    fontSize: ['1.25rem', '', '2rem'],
    margin: '1rem 0rem',
    textTransform: 'capitalize'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    margin: '1rem 1rem 0rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: ['', '', '75vh'],
        boxShadow: '2px 4px 13px #8a8a8a'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '2',
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: ['', '', '75vh'],
        boxShadow: '2px 4px 13px #8a8a8a'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem',
      height: 'auto'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light',
      // padding: '0rem',
      fontSize: ['2rem', '', '3.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white',
        margin: '0rem'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    zIndex: ['1'],
    maxHeight: ['', '', '75vh'],
    margin: '1rem',
    width: 'calc(100% - 2rem)',
    '.imageContainer': {
      order: ['2', ''],
      img: {
        boxShadow: '2px 4px 13px #8a8a8a',
        objectFit: 'contain'
      }
    },
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', ''],
      backgroundColor: '#ffffff',
      position: ['', '', 'relative'],
      top: '2rem',
      left: '3rem',
      border: 'solid 2px',
      borderColor: 'secondary',
      width: ['', '', '60%'],
      maxWidth: '90vw',
      boxShadow: '2px 4px 13px #8a8a8a'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '0rem 0rem 1rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'primary',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  // homepageMenu: {
  //   variant: 'customVariants.sideBySide2',
  // },

  homepageMenu: {
    backgroundColor: 'background',
    margin: '3rem 0rem',
    padding: ['', '', '4rem 0rem'],
    // backgroundImage:
    //   "url('https://res.cloudinary.com/gonation/image/upload/v1622126243/sites/kokomos/pineapple.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['30%', '', '25%'],
    backgroundPosition: ['top left', '', 'left center'],
    '.hero_content_container': {
      margin: ['', '', '0rem 0rem 2rem auto', '', '0rem 2rem 2rem auto'],
      position: 'static',
      padding: ['2rem 1rem', '', '0rem 1rem', '0rem 1rem 0rem 3rem', '0rem 1rem 0rem 5rem'],
      borderLeft: ['', '', '3px solid'],
      borderColor: ['', '', 'brandOrange'],
      left: '45%',
      width: ['', '', '55%']
    },
    '.title': {
      variant: 'customVariants.title',
      position: ['relative', '', 'absolute'],
      border: 'solid 1px',
      borderColor: 'secondary',
      fontSize: ['2rem', '', '2.25rem', '2.5rem', '2.5rem', '3rem'],
      paddingLeft: ['', '', '1rem', '2rem', '3rem'],
      display: 'flex',
      alignItems: 'center',
      left: ['0rem', '', '1rem'],
      top: '15%',
      lineHeight: '1.5',
      marginBottom: ['1.5rem', '', '0rem'],
      maxWidth: 'unset',
      width: ['100%', '', '40%']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '', '2rem'],
      whiteSpace: ['', '', '', 'nowrap'],
      fontWeight: 'bold'
    },
    '.text': {
      variant: 'customVariants.text',
      // textTransform: 'uppercase',
      fontSize: ['1rem', '', ''],
      color: '#76a2a9'
    },
    a: {
      order: '4',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    },

    '.slick-slider': {
      border: ['', '', '10px solid'],
      borderRight: ['', '', 'none'],
      borderColor: ['', '', 'primary'],
      width: ['100%', '', '', '85%'],
      left: ['', '', '', '15%'],
      top: ['', '', '2rem'],
      boxShadow: '2px 4px 13px #8a8a8a',
      '.slick-slide': {
        img: {
          filter: 'brightness(1)'
        }
      },
      '.slick-prev': {
        backgroundColor: 'secondary'
      },
      '.slick-next': {
        backgroundColor: '#bcd1e7',
        color: 'brandBlue'
      }
    }
  },

  homepageCocktails: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide2'
  },

  homepageChef: {
    padding: ['15vh 1rem', '', '20vh 1rem'],
    margin: ['0rem', '', '5rem 0rem 0rem'],
    '.section': {
      width: '75%',
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: '0rem 0rem 0rem 10%'
    },
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      // border: 'none',
      // textAlign: 'left',
      color: 'light',
      // textTransform: 'capitalize',
      fontSize: ['1.5rem', '2rem', '3rem', '4rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white',
      fontFamily: 'display',
      borderTop: '8px solid',
      borderColor: 'secondary',
      paddingTop: '1.5rem'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    },
    '.textContent': {
      display: 'none'
    }
  },

  titleBlock: {
    padding: '5rem 1rem 7rem',
    '.title': {
      variant: 'customVariants.title',
      margin: '0 auto',
      fontSize: ['2rem', '', '3rem'],
      fontFamily: 'display',
      textTransform: 'capitalize'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    background: 'unset',
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    // '.menuContainer': {
    //   // border: 'solid 1px black',
    //   // padding: ['1rem', '1rem'],
    //   // margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    //   // width: ['calc(100% - 1rem)'],
    //   // backgroundColor: 'white',
    //   // paddingBottom: '1rem',
    //   padding: '0rem',
    // },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    // '.menuItemInnerContainer, .menuItemContentContainer': {
    //   margin: '0rem',
    // },
    '.menuSectionTitle': {
      textTransform: 'uppercase',
      color: 'primary',
      borderTop: 'solid 2px',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem 2rem'],
      padding: '1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.menuItemName': {
      color: 'black'
    },

    '.menuItemDescription': {
      color: 'primary'
    }
    // '.menuPriceContainer': {
    //   // display: 'none',
    // },
    // '.variantsContainer': {
    //   // display: 'none',
    // },
  },

  catering: {
    // '.menuContainer': {
    //   // border: 'solid 1px black',
    //   // padding: ['1rem', '1rem'],
    //   // margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    //   // width: ['calc(100% - 1rem)'],
    //   // backgroundColor: 'white',
    //   // paddingBottom: '1rem',
    //   padding: '0rem',
    // },
    '.menuSliderContainer': {
      display: 'none!important'
    },
    '.innerMenuContainer': {
      width: '100%',
      margin: 0
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    // '.menuItemInnerContainer, .menuItemContentContainer': {
    //   margin: '0rem',
    // },
    '.menuSectionTitle': {
      textTransform: 'uppercase',
      color: 'primary',
      borderTop: 'solid 2px',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem 2rem'],
      padding: '1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '', '2rem']
    },
    '.menuItemName': {
      color: 'black'
    },

    '.menuItemDescription': {
      color: 'primary'
    }
    // '.menuPriceContainer': {
    //   // display: 'none',
    // },
    // '.variantsContainer': {
    //   // display: 'none',
    // },
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'secondary',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Private Events  ====
  // ? ========================

  privateEventsSlider: {
    order: '4',
    width: ['90%', '80%'],
    margin: ['5% 5% 10rem', '5% 10% 10%'],
    height: '65vh',
    padding: '2rem 5%',
    border: 'solid 3px',
    borderColor: 'secondary',
    '::before': {
      content: "''",
      width: '96%',
      height: '115%',
      border: 'solid 3px',
      borderColor: 'secondary',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },
    '.hero_content_container': {
      top: 'unset',
      bottom: '-2rem',
      left: '50%',
      margin: '0rem',
      transform: 'translate(-50%, 50%)',
      color: 'white',
      textAlign: 'center',
      backgroundColor: 'primary',
      border: 'solid 5px',
      borderColor: 'secondary',
      width: '85%',
      padding: ['1rem', '2rem']
    },
    '.slick-initialized': {
      height: '65vh',
      '.slick-slide > div': {
        height: '65vh',
        img: {
          height: '65vh',
          filter: 'brightness(1.1)'
        }
      },
      '.slick-arrow': {
        backgroundColor: 'primary',
        color: 'white',
        height: '40px',
        width: '40px',
        padding: '0.25rem'
      },
      '.slick-prev': {},
      '.slick-next': {}
    }
  },

  // ? ========================
  // ? ====  Reservations  ====
  // ? ========================

  reservations: {
    padding: ['2rem 1rem', '', '5rem 1rem'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================
  pressMenu: {
    paddingTop: [0, 0, 0, 0],
    backgroundColor: 'transparent',
    '.box': {
      width: ['100%', '', 'calc(50% - 1rem)', 'calc(25% - 1rem)'],
      backgroundColor: 'transparent'
    },
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'transparent' //'#347447b0',
    },
    '.title, .subtitle, .text': {
      // color: 'black',
    }
  },

  pressPage: {},

  // pressMenu: {
  //   padding: ['2rem 1rem', '2rem 1rem', '2rem 1rem'],
  //   backgroundColor: 'white',
  //   '.boxesHeading': {
  //     display: 'none'
  //   },
  //   '.boxesContainer': {
  //     justifyContent: 'center'
  //   },
  //   '.imageFill': {
  //     paddingBottom: '50%'
  //   },
  //   '.imageContainer': {
  //     img: {
  //       objectFit: 'contain'
  //     }
  //   },
  //   '.title': {
  //     fontSize: '1.2rem'
  //   },
  //   '.subtitle': {
  //     fontSize: '1rem',
  //     textTransform: 'capitalize'
  //   }
  // },

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {
    '.boxesHeading': {
      display: 'none'
    },
    padding: ['2rem 1rem', '2rem 1rem', '2rem 1rem'],
    '.boxesContainer': {
      justifyContent: 'center'
    },
    '.box': {
      maxWidth: ['', '', '50%']
    },
    '.imageFill': {
      paddingBottom: '60%'
    },
    '.textContent': {
      left: '0px',
      backgroundColor: '#000000cc'
    }
  },

  teamPage: {
    '.title': {
      fontSize: '1.75rem',
      marginBottom: '2rem'
    },
    '.subtitle': {
      fontSize: '1.25rem',
      textTransform: 'capitalize'
    },
    '.imageContainer': {
      width: ['', '', '35%']
    },
    '.contentRow': {
      width: ['', '', '65%'],
      paddingTop: '2rem'
    }
  },

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
